import { useAddToHomescreenPrompt } from "./AddToHomeScreen";
import { MapPinIcon, BuildingStorefrontIcon, TagIcon, BanknotesIcon, ShieldCheckIcon, WrenchScrewdriverIcon, TruckIcon, FireIcon } from '@heroicons/react/24/outline';
import Contact from "../Footer/Contact";
import { useState } from "react";


const aboutUSTitles = [
  {
    title: "من نحن؟",
    description: "مرحبا بكم في منصة أمين يوفر موقع أمين حلول متكاملة لأصحاب المشاريع المنزليه حلولنا تشمل:",
  },
];

const features = [
  {
    name: 'صفحه الكترونيه خاصه لصاحب المشروع لعرض منتجاته.',
    description:
      'أحصل على متجرك الإلكتروني المتكامل وانطلق في عالم التجارة الإلكترونية.',
    icon: BuildingStorefrontIcon,
  },
  {
    name: 'خدمة دفع اونلاين.',
    description:
      'نظام متكامل و آمن للدفع يمكنك من التركيز على صنع منتجات رائعة.',
    icon: BanknotesIcon,
  },
  {
    name: 'خدمة توصيل.',
    description:
      'لا تقلق بشأن توظيف فريق توصيل الطلبات الخاص بك. اترك توصيل الطلبات لنا.',
    icon: MapPinIcon,
  },
  {
    name: 'جميع حلولنا مجانية لأصحاب المشاريع المنزلية.',
    description:
      'يدفع الزبائن تكلفة توصيل منخفضة للطلبات التي يتم توصيلها عبرنا.',
    icon: TagIcon,
  },
]


const offers = [
  {
    name: "بوابة دفع آمنه",
    description: "استلم مدفوعاتك من البنوك المحلّية ",
    href: "",
  },
  {
    name: "توصيل مريح",
    description: "لا تشغل بالك بتوظيف سائقين",
    href: "",
  },
  {
    name: "انشئ متجرك الالكتروني مجانا",
    description: "احصل على المزيد من الطلبات",
    href: "",
  },
];




const perks = [
  {
    name: "ضاعف ارباحك",
    SVGd: FireIcon,
    description:
      "استقطب زبائن جدد عند انشاء متجرك الخاص في منصة امين",
  },
  {
    name: "خدمة توصيل سلسه",
    SVGd: TruckIcon,
    description:
      "نتكفل بخدمة توصيل منتجاتك بأمان",
  },
  {
    name: "دعم فني",
    SVGd: WrenchScrewdriverIcon,
    description:
      'ندير لك متجرك الالكتروني. لتحصل على الدعم الذي تحتاجه، تواصل معانا بسهولة',
  },
  {
    name: "نظام آمن",
    SVGd: ShieldCheckIcon,
    description:
      "قم بتحصيل اموالك باستخدام بوابة دفع آمنه",
  },
];

const meetItems = [
  {
    title: "فريقنا",
    description: "انضم إلينا وتعرف على فريق أمين من المبدعين والذين يعملون بجد لتوفير حلول متكاملة لأصحاب المشاريع المنزليه في الكويت لبناء مستقبل جديد.",
  }
];

const people = [
  {
    name: 'علي عوض',
    role: ' العضو المؤسس ومسؤول الدعم الفني',
    imageUrl:
      'https://res.cloudinary.com/dj9bn0knz/image/upload/v1717231286/u9ffrumpy6xho1kscfff.png',
  },
  {
    name: 'عبدالرحمن الصابري',
    role: 'العضو المؤسس ومسؤول العلاقات العامة',
    imageUrl:
      'https://res.cloudinary.com/dj9bn0knz/image/upload/v1717231286/i5c15bku6jwwawkripmf.png',
  },
  {
    name: 'محمد الرشيدان',
    role: 'مسؤول العمليات',
    imageUrl:
      'https://res.cloudinary.com/dj9bn0knz/image/upload/v1717409161/yvsqffyqd1mnloqqn1bx.png',
  },
  {
    name: 'أحمد الفضلي',
    role: 'مسؤول الخدمات اللوجستية',
    imageUrl:
      'https://res.cloudinary.com/dj9bn0knz/image/upload/v1717233056/fvtj0wh9qgi6gkhstwhh.png',
  },
];

const heroItems = [
  {
    title: "أمين … بيت المشاريع المنزلية",
    description: "انضم إلينا وتعرف على فريق أمين من المبدعين والذين يعملون بجد لتوفير حلول متكاملة لأصحاب المشاريع المنزليه في الكويت لبناء مستقبل جديد.",
    CTA: "انضم الآن",
  },
];



export default function Example() {

  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [contact, setContact] = useState(false);

  return (
    <>
    <Contact open={contact} onClose={() => setContact(false)}/>
    <div className="bg-white dark:bg-gray-800 dark:text-white">
      <main>
        {/* Hero */}
        <div className="flex flex-col border-b border-gray-200 dark:border-gray-600 lg:border-0">
          <nav aria-label="Offers" className="order-last lg:order-first">
            <div className="mx-auto max-w-7xl lg:px-8">
              <ul
                role="list"
                className="grid grid-cols-1 divide-y divide-gray-200 dark:divide-gray-600 lg:grid-cols-3 lg:divide-y-0 lg:divide-x"
              >
                {offers.map((offer) => (
                  <li key={offer.name} className="flex flex-col">
                    <a
                      href={offer.href}
                      className="relative flex flex-1 flex-col justify-center bg-white dark:bg-gray-800 py-6 px-4 text-center focus:z-10"
                    >
                      <p onClick={promptToInstall} className="text-sm text-gray-500 dark:text-gray-400">{offer.name}</p>
                      <p className="font-semibold text-gray-900 dark:text-white">
                        {offer.description}
                      </p>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </nav>

          <div className="relative">
            <div
              aria-hidden="true"
              className="absolute hidden h-full w-1/2 bg-gray-100 lg:block dark:text-white"
            />
            <div className="relative bg-gray-100 lg:bg-transparent dark:bg-gray-600">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:grid lg:grid-cols-2 lg:px-8">
                <div className="mx-auto max-w-2xl py-24 lg:max-w-none lg:py-64">
                  <div dir="rtl" className="lg:pr-16">
                    <h1 className="font-serif text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl xl:text-6xl   dark:text-white">
                      {heroItems[0].title}
                    </h1>
                    <p className="mt-4 text-xl text-gray-600 dark:text-gray-400">
                      {heroItems[0].description}
                    </p>
                    <div className="mt-6">
                      <a
                        href="/foter"
                        className="inline-block rounded-md border border-transparent bg-indigo-600 py-3 px-8 font-medium text-white hover:bg-indigo-700 dark:text-white"
                      >
                        {heroItems[0].CTA}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-48 w-full sm:h-64 lg:absolute lg:top-0 lg:right-0 lg:h-full lg:w-1/2">
              <img
                src="https://tailwindui.com/img/ecommerce-images/home-page-02-hero-half-width.jpg"
                alt=""
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>
        </div>


        {/* About us */}
        <div dir="rtl" className="bg-white py-24 sm:py-32 dark:bg-gray-800">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-white">
                {aboutUSTitles[0].title}
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-400">
                {aboutUSTitles[0].description}
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-16">
                    <dt className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
                      <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                        <feature.icon className="h-6 w-6 text-white " aria-hidden="true" />
                      </div>
                      {feature.name}
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600 dark:text-gray-400">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>

        {/* Our Team */}
        <div className="bg-gray-50 py-24 sm:py-32 dark:bg-gray-600">
          <div dir="rtl" className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
            <div className="max-w-2xl">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-white">{meetItems[0].title}</h2>
              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-400">
                {meetItems[0].description}
              </p>
            </div>
            <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
              {people.map((person) => (
                <li key={person.name}>
                  <div className="flex items-center gap-x-6">
                    <img className="h-16 w-16 rounded-full" src={person.imageUrl} alt="" />
                    <div>
                      <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900  dark:text-white">{person.name}</h3>
                      <p className="text-sm font-semibold leading-6 text-indigo-600 dark:text-gray-400">{person.role}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </main>
      <main>

        {/* info */}
        <section
          aria-labelledby="perks-heading"
          className="border-t border-gray-200  dark:border-gray-600 bg-white dark:bg-gray-800"
        >
          <h2 id="perks-heading" className="sr-only">
            Our perks
          </h2>

          <div className="mx-auto max-w-7xl py-24 px-4 sm:px-6 sm:py-32 lg:px-8">
            <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-0">
              {perks.map((perk) => (
                <div
                  key={perk.name}
                  className="text-center md:flex md:items-start md:text-left lg:block lg:text-center  dark:text-white"
                >
                  <div className="md:flex-shrink-0">
                    <div className="flow-root">
                      <perk.SVGd className="mx-auto h-24 w-24 text-black dark:text-white" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="mt-6 md:mt-0 md:ml-4 lg:mt-6 lg:ml-0">
                    <h3 className="text-base font-medium text-gray-900 dark:text-white">
                      {perk.name}
                    </h3>
                    <p className="mt-3 text-sm text-gray-500 dark:text-gray-400">
                      {perk.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </div>
    </>
  );
}

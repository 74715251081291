
const TAItems = [
    {
        title: ["الشروط و الأحكام", "شروط و احكام عامه:", "شروط و احكام (للمشتري):", "شروط و احكام (للبائع):"],
        listedItemsTAGeneral: [
            "أمين منصة تجمع المشاريع المنزلية. و عليه، فإن منصة أمين تخلي مسؤوليتها عن أي عيوب أو أخطاء في المنتج.",
            "منصة أمين قد تستخدم بعض البيانات لأغراض تجارية (هذه البيانات لا تشمل البيانات الشخصية للمشتري أو البائع)",
            "لا تلتزم منصة أمين بتعويض البائع أو المشتري في حين التوقيف المؤقت أو التوقف الكامل للمنصة. ",
            "قد تقوم منصة أمين بمراقبة كافة الانظمة في أي وقت او باستمرار.",
            "يجوز لمنصة أمين تعديل أو اضافة أو حذف بنود في الشروط و الأحكام في المستقبل.",
        ],
        listedItemsTACustomer: [
            "لاتمام عملية شرائية، على المشتري تكوين حساب في منصة أمين.",
            "عند تكوين حساب للمشتري في منصة أمين، فإن المشتري يتعهد بالإلتزام بالشروط و الأحكام الخاصة بالمنصة.",
            "تسمح منصة أمين لكل صاحب عضوية في المنصه ان يقوم بالطلب من خلال المنصة على ان يلتزم بالشروط و الأحكام.",
            "يلتزم اعضاء منصة أمين بتزويد المنصة بالبيانات الصحيحة لاتمام عملية الطلب (البيانات تشمل: عنوان - رقم هاتف).",
            "المشتري يتحمل رسوم التوصيل (٣ د.ك) (في حال اتمام الطلب، رسوم التوصيل لا تسترد).",
            "البضاعه لا ترد و لا تستبدل.",
            "يتم توصيل الطلبات بعد شراء المشتري للمنتج بالمدة التي يحددها البائع على ألا تقل هذه المدة عن يوم من اتمام المشتري للطلبية.",
            "يتم الدفع عن طريق كي نت عند اتمام الطلب.",
            "يحق لمنصة أمين الغاء عضوية أي مشتري.",
        ],
        listedItemsTASeller: [
            "في حين موافقة البائع باضافة منتجاته في منصة أمين، فهذا يعتبر اقرار من البائع بالالتزام بالشروط و الاحكام الخاصة بالمنصة.",
            "البائع مسؤول عن جودة المنتج و يتكفل عن العيوب المصنعية.",
            "البائع مسؤول عن قانونية البضاعة التي يوفرها.",
            "البائع مسؤول عن توفير البيانات المطلوبه للمنتجات لاضافتها في المنصه.",
            "رسوم التوصيل حق لمنصة أمين.",
            "سائق التوصيل يستلم البضاعه من البائع في الساعه المتفق عليها",
        ],

    },
];

export default function TermsAndCondition({ open, onClose, children }) {
    return (
        <>
            <div onClick={onClose} className={`fixed inset-0 flex justify-center items-center transition-colors ${open ? "visible bg-black/20" : "invisible"}`}>
                <div onClick={(e) => e.stopPropagation()} class={`bg-white dark:bg-gray-400 rounded-xl shadow p-6 transition-all  ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}`} >
                    {children}
                    {/* Modal content */}
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 z-50">
                        {/* Modal header */}
                        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                {TAItems[0].title[0]}
                            </h3>
                            <button onClick={onClose} type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>
                        {/*  Modal body  */}
                        <div dir="rtl" class="p-4 md:p-5 space-y-4">
                            <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].title[1]}</p>
                            <ol class="list-decimal">
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTAGeneral[0]}</li>
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTAGeneral[1]}</li>
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTAGeneral[2]}</li>
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTAGeneral[3]}</li>
                            </ol>
                            <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].title[2]}</p>
                            <ol class="list-decimal">
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTACustomer[0]}</li>
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTACustomer[1]}</li>
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTACustomer[2]}</li>
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTACustomer[3]}</li>
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTACustomer[4]}</li>
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTACustomer[5]}</li>
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTACustomer[6]}</li>
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTACustomer[7]}</li>
                            </ol>
                            <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].title[3]}</p>
                            <ol class="list-decimal">
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTASeller[0]}</li>
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTASeller[1]}</li>
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTASeller[2]}</li>
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTASeller[3]}</li>
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTASeller[4]}</li>
                                <li class="text-base leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTASeller[5]}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


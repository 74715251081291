import React, { useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import {RxDotFilled} from "react-icons/rx";

const slides = [
    {
        url: 'https://res.cloudinary.com/dj9bn0knz/image/upload/v1717235087/xlwi3xrbofysioepxhge.png',
    },
    {
        url: 'https://res.cloudinary.com/dj9bn0knz/image/upload/v1718972465/blog-api/rqw4yuyvdktrdnccr9hv.png',
    },
    {
        url: 'https://res.cloudinary.com/dj9bn0knz/image/upload/v1718972464/blog-api/hjkxc9jwl9f2mtakovnh.png',
    },
    {
        url: 'https://res.cloudinary.com/dj9bn0knz/image/upload/v1718972463/blog-api/pxdb6mgirl6hbeyacyxp.png',
    },
    {
        url: 'https://tailwindui.com/img/ecommerce-images/home-page-02-edition-01.jpg',
    },
];

export default function Carousel() {

    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };
    const nextSlide = () => { 
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };
    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    }

    return (
        <div className="max-w-[1400px] h-[512px] w-full m-auto py-16 px-4 relative group">
            <div style={{ backgroundImage: `url(${slides[currentIndex].url})` }} className="w-full h-full rounded-2xl bg-center bg-cover duration-500">
            </div>
            {/* Left Arrow */}
            <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
                <BsChevronCompactLeft onClick={prevSlide} />
            </div>
            {/* Right Arrow */}
            <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
                <BsChevronCompactRight onClick={nextSlide} />
            </div>
            <div className="flex top-4 justify-center py-2">
                {slides.map((slide, slideIndex)=>(
                    <div key={slideIndex} onClick={() => goToSlide(slideIndex)} className="text-2xl cursor-pointer text-black dark:text-white">
                        <RxDotFilled />
                    </div>
                ))}
            </div>
        </div>
    )
}
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCategoriesAction } from "../../redux/slices/categories/categoriesSlice";

const titles = [
  {
    title: ["الماركات"],
  },
]

const AllCategories = () => {
  //dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCategoriesAction());
  }, [dispatch]);

  //get data from store
  const {
    categories: { categories },
  } = useSelector((state) => state?.categories);
  return (
    <>
      <div className="flex justify-center items-center bg-white dark:bg-gray-800">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div dir="rtl" className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32 ">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-white">{titles[0].title[0]} </h2>

            <div className="mt-4 space-y-6 lg:grid lg:grid-cols-4 lg:gap-x-6 lg:space-y-0">
              {categories?.map((category) => (
                <div key={category?.name} className="group relative">
                  <div className=" relative h-64 w-64 overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-1 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64 ">
                    <img
                      src={category?.image}
                      alt={category?.name}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <h3 className="mt-6 text-sm text-gray-500">
                    <Link to={`/products-filters-brand?category=${category?.name}`}>
                      <span className="absolute inset-0" />
                      {/* {category?.name} */}
                    </Link>
                  </h3>
                  {/* <p className="text-base font-semibold text-gray-900">{category?.name}</p> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllCategories;

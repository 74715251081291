import { Link } from "react-router-dom";
import logo from "./Ameen_Horizontal_Logo_One_Color_RGB.svg";
import TermsAndCondition from "./TermsAndCondition";
import Location from "./Location";
import Contact from "./Contact";
import { useState } from "react";

const footerItems = [
    {
        Header: 'من نحن',
    },
    {
        Header: 'الشروط و الأحكام',
    },
    {
        Header: 'الموقع',
    },
    {
        Header: 'تواصل معنا',
    },
];
const contactItems = [
    {
        contactLink: "tel: +96565067851",
        SVGd:
        "M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z",
    },
    {
        contactLink: "mailto:info@ameen.ltd?subject=Mail from Our Site",
        SVGd:
        "m3.5 5.5 7.893 6.036a1 1 0 0 0 1.214 0L20.5 5.5M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z" ,
    },
    {
        contactLink: "https://www.instagram.com/ameen.q8",
        SVGd:
        "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z",
    },
    {
        contactLink: "https://x.com/ameen_storeq8",
        SVGd:
        "M13.795 10.533 20.68 2h-3.073l-5.255 6.517L7.69 2H1l7.806 10.91L1.47 22h3.074l5.705-7.07L15.31 22H22l-8.205-11.467Zm-2.38 2.95L9.97 11.464 4.36 3.627h2.31l4.528 6.317 1.443 2.02 6.018 8.409h-2.31l-4.934-6.89Z",
    },
    {
        contactLink: "https://www.linkedin.com/company/ameen-ltd" ,
        SVGd:
        "M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z",
    },
];
const footerSlogan = [
    {
        line:" منصة لدعم المشاريع المنزلية و توفير حلول متكاملة لبناء مستقبل جديد",
    },
];

export default function Footer() {
    const [term, setTerm] = useState(false);
    const [location, setLocation] = useState(false);
    const [contact, setContact] = useState(false);
    return (
        <>
            <TermsAndCondition open={term} onClose={() => setTerm(false)} />
            <Location open={location} onClose={() => setLocation(false)} />
            <Contact open={contact} onClose={() => setContact(false)}/>
            
            <footer id="foter" class="p-4 bg-gray-50 md:p-8 lg:p-10 dark:bg-gray-600">
                <div class="mx-auto max-w-screen-xl text-center">
                    <a href="/landing-page" class="flex justify-center items-center text-2xl font-semibold text-gray-900 dark:text-white">
                        <img src={logo} className="h-32 pt-2 w-auto dark:invert" />
                    </a>
                    <p class="my-6 text-gray-500 dark:text-gray-400">{footerSlogan[0].line}</p>
                    <ul class="flex flex-wrap justify-center items-center mb-6 text-gray-900 dark:text-white">
                        <li>
                            <a href="/landing-page" class="mr-4 hover:underline md:mr-6 ">{footerItems[0].Header}</a>
                        </li>
                        <li>
                            <a href="/terms-and-conditions" class="mr-4 hover:underline md:mr-6">{footerItems[1].Header}</a>
                        </li>
                        <li>
                            <button onClick={() => setLocation(true)}>
                                <p class="mr-4 hover:underline md:mr-6 ">{footerItems[2].Header}</p></button>
                        </li>
                        <li>
                        <button onClick={() => setContact(true)}><p class="mr-4 hover:underline md:mr-6">{footerItems[3].Header}</p></button>
                        </li>
                    </ul>
                    <div class="flex mt-4 space-x-6 justify-center sm:mt-0">
                        <a href={contactItems[0].contactLink} class="text-gray-500 hover:text-gray-900 dark:hover:text-white" target="_blank">
                            <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" aria-hidden="true">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d={contactItems[0].SVGd} />
                            </svg>
                        </a>
                        <a href={contactItems[1].contactLink} class="text-gray-500 hover:text-gray-900 dark:hover:text-white" target="_blank">
                            <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" aria-hidden="true">
                                <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d={contactItems[1].SVGd} />
                            </svg>
                        </a>
                        <a href={contactItems[2].contactLink} class="text-gray-500 hover:text-gray-900 dark:hover:text-white" target="_blank">
                            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d={contactItems[2].SVGd} clip-rule="evenodd" /></svg>
                        </a>
                        <a href={contactItems[3].contactLink} class="text-gray-500 hover:text-gray-900 dark:hover:text-white" target="_blank">
                            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path d={contactItems[3].SVGd} />
                            </svg>

                        </a>
                        <a href={contactItems[4].contactLink} class="text-gray-500 hover:text-gray-900 dark:hover:text-white" target="_blank">
                            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path fill-rule="evenodd" d={contactItems[4].SVGd} clip-rule="evenodd" />
                                <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </footer>
        </>
    );
}


import { Fragment, useEffect, useState } from "react";
import {
  Dialog,
  Disclosure,
  Menu,
  Transition,
  RadioGroup,
} from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import Products from "./Products";
import { useSearchParams } from "react-router-dom";
import baseURL from "../../../utils/baseURL";
import { fetchProductsAction } from "../../../redux/slices/products/productSlices";
import { fetchBrandsAction } from "../../../redux/slices/categories/brandsSlice";
import { fetchColorsAction } from "../../../redux/slices/categories/colorsSlice";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { fetchCategoriesAction } from "../../../redux/slices/categories/categoriesSlice";
import { Button } from "flowbite-react";

const titles = [
  {
    title: ["المنتجات","لون","سعر","ماركات","مقاس"],
  },
];

const sortOptions = [
  { name: "Most Popular", href: "#", current: true },
  { name: "Best Rating", href: "#", current: false },
  { name: "Newest", href: "#", current: false },
  { name: "Price: Low to High", href: "#", current: false },
  { name: "Price: High to Low", href: "#", current: false },
];

const allPrice = [
  {
    amount: "0 - 50",
  },
  {
    amount: "50 - 100",
  },
  {
    amount: "100 - 150",
  },
  {
    amount: "150 - 200",
  },
  {
    amount: "200 - 250",
  },
  {
    amount: "250 - 300",
  },
  {
    amount: "300 - 350",
  },
  {
    amount: "350 - 400",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const sizeCategories = ["S", "M", "L", "XL", "XXL"];

export default function ProductsFilters() {
  //dispatch
  const dispatch = useDispatch();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  //get query string
  const [params, setParams] = useSearchParams();
  const brand = params.get("brand");
  const [page, setPage] = useState(1);
  //filters
  const [color, setColor] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [size, setSize] = useState("");

  let productUrl = `${baseURL}/products`;
  if (brand) {
      productUrl = `${baseURL}/products?brand=${brand}&page=3&limit=8`;
  }

  if (page) {
      productUrl = `${baseURL}/products?brand=${brand}&page=${page}&limit=8`;
  }

  //fetch all products
  useEffect(() => {
    dispatch(
      fetchProductsAction({
        url: productUrl,
      })
    );
  }, [dispatch, brand, page]);
  //get store data
  const {
    products: { products },
    loading,
    error,
  } = useSelector((state) => state?.products);

  //fetch categories
  useEffect(() => {
    dispatch(
      fetchCategoriesAction({
        url: productUrl,
      })
    );
  }, [dispatch]);
  //get store data
  const {
    categories: { categories },
  } = useSelector((state) => state?.categories);

    //fetch brands
    useEffect(() => {
      dispatch(
        fetchBrandsAction({
          url: productUrl,
        })
      );
    }, [dispatch]);
    //get store data
    const {
      brands: { brands },
    } = useSelector((state) => state?.brands);

  //fetch colors
  useEffect(() => {
    dispatch(
      fetchColorsAction({
        url: productUrl,
      })
    );
  }, [dispatch]);

  //get store data
  const {
    colors: { colors },
  } = useSelector((state) => state?.colors);

  let colorsLoading;
  let colorsError;

  let productsLoading;
  let productsError;

  return (
    <div className="bg-white dark:bg-gray-800 dark:text-white">
      <div>
        {/* Mobile menu */}

      </div>

      <div>

        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div dir="rtl" className="flex items-baseline justify-between border-b border-gray-200 pt-24 pb-6">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 dark:text-white">
            {titles[0].title[0]}
            </h1>
            {/* sort */}
            <div className="flex items-center">
              <Menu as="div" className="relative inline-block text-left">
                {/* <div>
                  <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    Sort
                    <ChevronDownIcon
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div> */}

                {/* sort item links */}
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95">
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {sortOptions.map((option) => (
                        <Menu.Item key={option.name}>
                          {({ active }) => (
                            <a
                              href={option.href}
                              className={classNames(
                                option.current
                                  ? "font-medium text-gray-900"
                                  : "text-gray-500",
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm"
                              )}>
                              {option.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="pt-6 pb-24">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            <div className="mx-auto max-w-7xl py-4 px-4 sm:px-8 sm:py-32 lg:px-8 lg:pt-8">
              {/* Desktop  Filters */}


              {/* Product grid */}
              {loading ? (
                <LoadingComponent />
              ) : error ? (
                <ErrorMsg message={error?.message} />
              ) : products?.length <= 0 ? (
                <NoDataFound />
              ) : (
                <Products products={products} />
              )}
               {/* Pagnation */}
               <div dir="ltr" class="flex items-center justify-center">
                            {page != 1 ?
                                <Button onClick={() => setPage(page - 1)} href="#" class="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 19-7-7 7-7" />
                                    </svg>

                                </Button>
                                : <button disabled onClick={() => setPage(page - 1)} href="#" class="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-gray-400 border border-gray-300 rounded-lg hover:bg-gray-900 hover:text-gray-700 dark:bg-gray-300 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-400 dark:hover:text-white">
                                    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 19-7-7 7-7" />
                                    </svg>

                                </button>
                            }
                            {products?.length < 8 ?
                                <button disabled onClick={() => setPage(page + 1)} href="#" class="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-gray-400 border border-gray-300 rounded-lg hover:bg-gray-900 hover:text-gray-700 dark:bg-gray-300 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-400 dark:hover:text-white">
                                    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7" />
                                    </svg>

                                </button> :
                                <Button onClick={() => setPage(page + 1)} href="#" class="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7" />
                                    </svg>

                                </Button>
                            }
                        </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}

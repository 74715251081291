
const TAItems = [
    {
        title: ["الشروط و الأحكام", "شروط و احكام عامه:", "شروط و احكام (للمشتري):", "شروط و احكام (للبائع):"],
        listedItemsTAGeneral: [
            "أمين منصة تجمع المشاريع المنزلية. و عليه، فإن منصة أمين تخلي مسؤوليتها عن أي عيوب أو أخطاء في المنتج.",
            "منصة أمين قد تستخدم بعض البيانات لأغراض تجارية (هذه البيانات لا تشمل البيانات الشخصية للمشتري أو البائع)",
            "لا تلتزم منصة أمين بتعويض البائع أو المشتري في حين التوقيف المؤقت أو التوقف الكامل للمنصة. ",
            "قد تقوم منصة أمين بمراقبة كافة الانظمة في أي وقت او باستمرار.",
            "يجوز لمنصة أمين تعديل أو اضافة أو حذف بنود في الشروط و الأحكام في المستقبل.",
        ],
        listedItemsTACustomer: [
            "لاتمام عملية شرائية، على المشتري تكوين حساب في منصة أمين.",
            "عند تكوين حساب للمشتري في منصة أمين، فإن المشتري يتعهد بالإلتزام بالشروط و الأحكام الخاصة بالمنصة.",
            "تسمح منصة أمين لكل صاحب عضوية في المنصه ان يقوم بالطلب من خلال المنصة على ان يلتزم بالشروط و الأحكام.",
            "يلتزم اعضاء منصة أمين بتزويد المنصة بالبيانات الصحيحة لاتمام عملية الطلب (البيانات تشمل: عنوان - رقم هاتف).",
            "المشتري يتحمل رسوم التوصيل (٣ د.ك) (في حال اتمام الطلب، رسوم التوصيل لا تسترد).",
            "البضاعه لا ترد و لا تستبدل.",
            "يتم توصيل الطلبات بعد شراء المشتري للمنتج بالمدة التي يحددها البائع على ألا تقل هذه المدة عن يوم من اتمام المشتري للطلبية.",
            "يتم الدفع عن طريق كي نت عند اتمام الطلب.",
            "يحق لمنصة أمين الغاء عضوية أي مشتري.",
        ],
        listedItemsTASeller: [
            "في حين موافقة البائع باضافة منتجاته في منصة أمين، فهذا يعتبر اقرار من البائع بالالتزام بالشروط و الاحكام الخاصة بالمنصة.",
            "البائع مسؤول عن جودة المنتج و يتكفل عن العيوب المصنعية.",
            "البائع مسؤول عن قانونية البضاعة التي يوفرها.",
            "البائع مسؤول عن توفير البيانات المطلوبه للمنتجات لاضافتها في المنصه.",
            "رسوم التوصيل حق لمنصة أمين.",
            "سائق التوصيل يستلم البضاعه من البائع في الساعه المتفق عليها",
        ],

    },
];

export default function TA() {
    return (
        <div class="bg-white dark:bg-gray-800">
            <h1 dir="rtl" class="flex items-center justify-center py-8 sm:py-16 text-4xl font-semibold text-gray-900 dark:text-white">{TAItems[0].title[0]}</h1>
            <div class="mx-auto flex items-center justify-center md:p-5 space-y-4">
                <div dir="rtl" class="mx-8 lg:mx-64">
                    <h2 class="text-xl leading-relaxed text-gray-900 dark:text-white">{TAItems[0].title[1]}</h2>
                    <ol class="list-decimal py-4 px-4">
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTAGeneral[0]}</li>
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTAGeneral[1]}</li>
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTAGeneral[2]}</li>
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTAGeneral[3]}</li>
                    </ol>
                    <h2 class="text-xl leading-relaxed text-gray-900 dark:text-white">{TAItems[0].title[2]}</h2>
                    <ol class="list-decimal py-4 px-4">
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTACustomer[0]}</li>
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTACustomer[1]}</li>
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTACustomer[2]}</li>
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTACustomer[3]}</li>
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTACustomer[4]}</li>
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTACustomer[5]}</li>
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTACustomer[6]}</li>
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTACustomer[7]}</li>
                    </ol>
                    <h2 class="text-xl leading-relaxed text-gray-900 dark:text-white">{TAItems[0].title[3]}</h2>
                    <ol class="list-decimal py-4 px-4">
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTASeller[0]}</li>
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTASeller[1]}</li>
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTASeller[2]}</li>
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTASeller[3]}</li>
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTASeller[4]}</li>
                        <li class="text-base py-2 leading-relaxed text-gray-500 dark:text-gray-400">{TAItems[0].listedItemsTASeller[5]}</li>
                    </ol>
                </div>
            </div>
        </div>
    );
}


import { Fragment, useEffect, useState } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  ShoppingCartIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import baseURL from "../../utils/baseURL";
import logo from "./logo3.png";
import { useDispatch, useSelector } from "react-redux";
import { getCartItemsFromLocalStorageAction } from "../../redux/slices/cart/cartSlices";
import { logoutAction } from "../../redux/slices/users/usersSlice";
import { fetchCouponsAction } from "../../redux/slices/coupons/couponsSlice";
import { fetchBrandsAction } from "../../redux/slices/categories/brandsSlice";

const navBarLinks = [
  {
    title: ["نسائي","رجالي","أطفال","تسجيل دخول","انشاء حساب"],
  },
];


export default function Navbar() {
  //dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBrandsAction());
  }, [dispatch]);
  //get data from store
  const { brands } = useSelector((state) => state?.brands);

  const brandsToDisplay = brands?.brands?.slice(0, 3);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  //get data from store
  useEffect(() => {
    dispatch(getCartItemsFromLocalStorageAction());
  }, [dispatch]);
  const { cartItems } = useSelector((state) => state?.carts);
  //get cart items from local storage
  let cartItemsFromLocalStorage;
  //get login user from localstorage

  const user = JSON.parse(localStorage.getItem("userInfo"));

  const isLoggedIn = user?.token ? true : false;
  //logout handler
  const logoutHandler = () => {
    dispatch(logoutAction());
    //reload
    window.location.reload();
  };
  //coupons
  useEffect(() => {
    dispatch(fetchCouponsAction());
  }, [dispatch]);
  //get coupons
  const { coupons, loading, error } = useSelector((state) => state?.coupons);
  //Get current coupon
  const currentCoupon = coupons
    ? coupons?.coupons?.[coupons?.coupons?.length - 1]
    : console.log(currentCoupon);

  //Dark Mode
  const [theme, setTheme] = useState("light");

  useEffect(() => {

    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }

  }, [theme]);

  const handleThemeSwitch = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  return (
    <div dir="rtl" className="bg-white dark:bg-gray-800 dark:text-white">
      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl dark:bg-gray-800">
                <div className="flex px-4 pt-5 pb-2">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                {/* mobile category menu links */}
                <div className="space-y-6 border-t border-gray-200 dark:border-gray-600 py-6 px-4">
                  {/* {navigation.pages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <a
                        href={page.href}
                        className="-m-2 block p-2 font-medium text-gray-900">
                        {page.name}
                      </a>
                    </div>
                  ))} */}
                    <>
                      <Link
                        to="/products-filters?brand=ملابس"
                        className="flex items-center text-sm font-medium text-gray-700 dark:text-white hover:text-gray-800"
                      >
                        ملابس
                      </Link>

                      <Link
                        to="/products-filters?brand=اجهزة"
                        className="flex items-center text-sm font-medium text-gray-700 dark:text-white hover:text-gray-800"
                      >
                        اجهزة
                      </Link>

                      <Link
                        to="/products-filters?brand=اكسسورات"
                        className="flex items-center text-sm font-medium text-gray-700 dark:text-white hover:text-gray-800"
                      >
                        اكسسورات
                      </Link>

                      <Link
                        to="/products-filters?brand=كتب"
                        className="flex items-center text-sm font-medium text-gray-700 dark:text-white hover:text-gray-800"
                      >
                        كتب
                      </Link>

                    </>
                </div>

                {/* mobile links register/login */}
                <div className="space-y-6 border-t border-gray-200 dark:border-gray-600 py-6 px-4 ">
                  {!isLoggedIn && (
                    <>
                      <div className="flow-root">
                        <Link
                          to="/register"
                          className="-m-2 block p-2 font-medium text-gray-900  dark:text-white"
                        >
                          {navBarLinks[0].title[4]}
                        </Link>
                      </div>
                      <div className="flow-root">
                        <Link
                          to="/login"
                          className="-m-2 block p-2 font-medium text-gray-900  dark:text-white"
                        >
                          {navBarLinks[0].title[3]}
                        </Link>
                      </div>
                    </>
                  )}
                </div>

                <div className="space-y-6 border-t border-gray-200 dark:border-gray-600 py-6 px-4"></div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <header className="relative z-10">
        <nav aria-label="Top">
          {/* Coupon navbar */}
          {!currentCoupon?.isExpired && (
            <div className="bg-yellow-600">
              <div className="mx-auto flex h-10 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                <p
                  style={{ textAlign: "center", width: "100%" }}
                  className="flex-1 text-center text-sm font-medium text-white lg:flex-none  dark:text-white"
                >
                  {currentCoupon
                    ? `${currentCoupon?.code}- ${currentCoupon?.discount}% , ${currentCoupon?.daysLeft}`
                    : "No Flash sale at moment"}
                </p>

                <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6"></div>
              </div>
            </div>
          )}
          {/* Top navigation  desktop*/}
          {!isLoggedIn && (
            <div className="bg-gray-800">
              <div className="mx-auto flex h-10 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                <div dir="ltr" className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                  {!isLoggedIn && (
                    <>
                      <Link
                        to="/register"
                        className="text-sm font-medium text-white hover:text-gray-100  dark:text-white"
                      >
                        {navBarLinks[0].title[4]}
                      </Link>
                      <span
                        className="h-6 w-px bg-gray-600"
                        aria-hidden="true"
                      />
                      <Link
                        to="/login"
                        className="text-sm font-medium text-white hover:text-gray-100  dark:text-white"
                      >
                        {navBarLinks[0].title[3]}
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* Desktop Navigation */}
          <div className="bg-white dark:bg-gray-800  dark:text-white">
            <div className="border-b border-gray-200 dark:border-gray-600">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  {/* Logo (lg+) */}
                  <div className="hidden lg:flex lg:items-center">
                    <Link to="/">
                      <span className="sr-only">Your Company</span>
                      <img
                        className="h-16 pt-2 w-auto dark:invert"
                        src={logo}
                        alt=""
                      />
                    </Link>
                  </div>

                  <div className="hidden h-full lg:flex ">
                    {/*  menus links*/}
                    <Popover.Group className="ml-8">
                      <div className="flex h-full justify-center space-x-4 rtl:space-x-reverse">
                          <>
                            <Link
                              to="/products-filters?brand=ملابس"
                              className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800  dark:text-white rtl:space-x-reverse"
                            >
                              ملابس
                            </Link>

                            <Link
                              to="/products-filters?brand=اجهزة"
                              className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800  dark:text-white rtl:space-x-reverse"
                            >
                              اجهزة
                            </Link>
                            <Link
                              to="/products-filters?brand=اكسسورات"
                              className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800  dark:text-white rtl:space-x-reverse"
                            >
                              اكسسورات
                            </Link>
                            <Link
                              to="/products-filters?brand=كتب"
                              className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800  dark:text-white rtl:space-x-reverse"
                            >
                              كتب
                            </Link>
                          </>
                      </div>
                    </Popover.Group>
                  </div>

                  {/* Mobile Naviagtion */}
                  <div className="flex flex-1 items-center lg:hidden">
                    <button
                      type="button"
                      className="-ml-2 rounded-md bg-white p-2 text-gray-400 dark:bg-gray-800 dark:text-white"
                      onClick={() => setMobileMenuOpen(true)}
                    >
                      <span className="sr-only">Open menu</span>
                      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  {/* logo */}
                  <Link to="/" className="lg:hidden">
                    <img
                      className="h-16 mt-2 w-auto dark:invert"
                      src={logo}
                      alt=""
                    />
                  </Link>

                  {/* login profile icon mobile */}
                  <div className="flex flex-1 items-center justify-end">
                    {user?.userFound?.isAdmin && (
                      <Link
                        to="/admin"
                        className="inline-flex items-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Admin Dashboard
                      </Link>
                    )}
                    <button className="p-4 rounded-3xl" onClick={handleThemeSwitch}>
                      {theme === "dark" ? <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M11.675 2.015a.998.998 0 0 0-.403.011C6.09 2.4 2 6.722 2 12c0 5.523 4.477 10 10 10 4.356 0 8.058-2.784 9.43-6.667a1 1 0 0 0-1.02-1.33c-.08.006-.105.005-.127.005h-.001l-.028-.002A5.227 5.227 0 0 0 20 14a8 8 0 0 1-8-8c0-.952.121-1.752.404-2.558a.996.996 0 0 0 .096-.428V3a1 1 0 0 0-.825-.985Z" clip-rule="evenodd" />
                      </svg> : <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5V3m0 18v-2M7.05 7.05 5.636 5.636m12.728 12.728L16.95 16.95M5 12H3m18 0h-2M7.05 16.95l-1.414 1.414M18.364 5.636 16.95 7.05M16 12a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" />
                      </svg>}
                    </button>
                    <div dir="ltr" className="flex items-center lg:ml-8">
                      <div className="flex space-x-8">
                        {isLoggedIn && (
                          <div className="flex">
                            <Link
                              to="/customer-profile"
                              className="-m-2 p-2 mr-2 text-gray-400 hover:text-gray-500 dark:text-white"
                            >
                              <UserIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </Link>
                            {/* logout */}
                            <button onClick={logoutHandler}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 text-gray-500 dark:text-white"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                                />
                              </svg>
                            </button>
                          </div>
                        )}
                      </div>
                      <span
                        className="mx-4 h-6 w-px bg-gray-200 lg:mx-6"
                        aria-hidden="true"
                      />
                      {/* login shopping cart mobile */}
                      <div dir="ltr" className="flow-root">
                        <Link
                          to="/shopping-cart"
                          className="group -m-2 flex items-center p-2"
                        >
                          <ShoppingCartIcon
                            className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-300"
                            aria-hidden="true"
                          />
                          <span className="ml-2 text-sm font-medium text-gray-700 dark:text-white  group-hover:text-gray-300">
                            {cartItems?.length > 0 ? cartItems?.length : 0}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

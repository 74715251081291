import React, { useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfileAction,
  updateUserShippingAddressAction,
} from "../../../redux/slices/users/usersSlice";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import SuccessMsg from "../../SuccessMsg/SuccessMsg";
import Swal from "sweetalert2";

const addShippingAddressHeadlines = [
  'الاسم الأول','أسم العائلة','المنطقة','القطعة','الشارع','المحافظة','الهاتف','الدولة','أضف عنوان الشحن','ملخص الطلب','تفاصيل الشحن','تأكد من معلوماتك','المنزل'
];



const AddShippingAddress = () => {
  const [reducerValue, forceUpdate] = useReducer (x => x + 1, 0);
  //dispatch
  const dispatch = useDispatch();
  //user profile
  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch, reducerValue]);

  const { loading, error, profile } = useSelector((state) => state?.users);
  const user = profile?.user;

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    area: "",
    block: "",
    street: "",
    houseNumber: "",
    governate: "",
    phone: "",
    country: "",
  });
  //onchange
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //onsubmit
  const onSubmit = async (e) =>  {
    e.preventDefault();
    await dispatch(updateUserShippingAddressAction(formData)).then(
      Swal.fire({
      icon: "success",
      title: "Good Job",
      text: "Product added to cart successfully",
    })
    );
    forceUpdate();
  };

  return (
    <>
      {error && <ErrorMsg message={error?.message} />}
      {/* shipping details */}
      {user?.hasShippingAddress ? (
        <div dir="rtl" className="mt-6">
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
          {addShippingAddressHeadlines[10]}
          </h3>

          <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
          {addShippingAddressHeadlines[11]}
          </p>
          <div>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
            {addShippingAddressHeadlines[0]} : {user?.shippingAddress?.firstName}
            </p>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
            {addShippingAddressHeadlines[1]} : {user?.shippingAddress?.lastName}
            </p>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
            {addShippingAddressHeadlines[2]} : {user?.shippingAddress?.area}
            </p>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
            {addShippingAddressHeadlines[3]} : {user?.shippingAddress?.block}
            </p>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
            {addShippingAddressHeadlines[4]} : {user?.shippingAddress?.street}
            </p>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
            {addShippingAddressHeadlines[12]} : {user?.shippingAddress?.houseNumber}
            </p>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
            {addShippingAddressHeadlines[5]} : {user?.shippingAddress?.governate}
            </p>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
            {addShippingAddressHeadlines[6]} : {user?.shippingAddress?.phone}
            </p>
            {/* <p className="mt-1 text-sm text-gray-500">
            {addShippingAddressHeadlines[7]} : {user?.shippingAddress?.country}
            </p> */}
          </div>
        </div>
      ) : (
        <form dir="rtl"
          onSubmit={onSubmit}
          className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
          <div>
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {addShippingAddressHeadlines[0]}
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="firstName"
                onChange={onChange}
                value={formData.firstName}
                autoComplete="given-name"
                className="block w-full rounded-md border-gray-300  p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="last-name"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {addShippingAddressHeadlines[1]}
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="lastName"
                onChange={onChange}
                value={formData.lastName}
                className="block w-full rounded-md border-gray-300  p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="area"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {addShippingAddressHeadlines[2]}
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="area"
                onChange={onChange}
                value={formData.area}
                autoComplete="area"
                className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="block"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {addShippingAddressHeadlines[3]}
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="block"
                onChange={onChange}
                value={formData.block}
                autoComplete="block"
                className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="street"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {addShippingAddressHeadlines[4]}
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="street"
                onChange={onChange}
                value={formData.street}
                autoComplete="street"
                className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="houseNumber"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {addShippingAddressHeadlines[12]}
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="houseNumber"
                onChange={onChange}
                value={formData.houseNumber}
                autoComplete="houseNumber"
                className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="governate"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {addShippingAddressHeadlines[5]}
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="governate"
                onChange={onChange}
                value={formData.governate}
                autoComplete="governate"
                className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {addShippingAddressHeadlines[6]}
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="phone"
                id="phone"
                onChange={onChange}
                value={formData.phone}
                autoComplete="telephone"
                className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="country"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {addShippingAddressHeadlines[7]}
            </label>
            <div className="mt-1">
              <select
                id="country"
                name="country"
                autoComplete="country"
                value={formData.country}
                onChange={onChange}
                className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                <option value="Kuwait">الكويت</option>
              </select>
            </div>
          </div>

          {loading ? (
            <LoadingComponent />
          ) : (
            <button
              type="submit"
              className="w-full rounded-md border border-transparent bg-indigo-600 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">
              {addShippingAddressHeadlines[8]}
            </button>
          )}
        </form>
      )}
    </>
  );
};

export default AddShippingAddress;


const contactItems = [
    {
        title: "تواصل معنا"
    },
    {
        contactLink: "mailto:info@ameen.ltd?subject=Mail from Our Site",
        contactName: "البريد الالكتروني: ",
        contactType: "info@ameen.ltd",
    },
    {
        contactLink: "https://www.instagram.com/ameen.q8",
        contactName: "إنستقرام: ",
        contactType: "@ameen.q8",
    },
    {
        contactLink: "tel: +96565067851",
        contactName:"رقم الهاتف: ",
        contactType: "65067851",
    },
];

export default function Contact({ open, onClose, children }) {
    return (
        <>
            <div onClick={onClose} className={`fixed inset-0 flex justify-center items-center transition-colors z-1000 ${open ? "visible bg-black/20" : "invisible"}`}>
                <div class={`bg-white dark:bg-gray-400 rounded-xl shadow p-6 transition-all  ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}`} >
                    {children}
                    {/* Modal content */}
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        {/* Modal header */}
                        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                            {contactItems[0].title}
                            </h3>
                            <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>
                        {/*  Modal body  */}
                        <div dir="rtl" class="p-4 md:p-5 space-y-4">
                        <table>
                            <tr>
                              <td>
                                <a class="text-gray-600 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white" href={contactItems[1].contactLink} target="_blank">{contactItems[1].contactName} {contactItems[1].contactType}</a></td>
                            </tr>
                            <tr>
                                <td><a class="text-gray-600 dark:text-gray-400  dark:hover:bg-gray-600 dark:hover:text-white" href={contactItems[2].contactLink}target="_blank">{contactItems[2].contactName} {contactItems[2].contactType}</a></td>
                            </tr>
                            <tr>
                                <td>
                                    <a class="text-gray-600 dark:text-gray-400  dark:hover:bg-gray-600 dark:hover:text-white" href={contactItems[3].contactLink} target="_blank">{contactItems[3].contactName} {contactItems[3].contactType}</a>
                                    </td>
                            </tr>
                          </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


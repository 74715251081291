import React from "react";
import { Link } from "react-router-dom";
import { RadioGroup } from "@headlessui/react";

const Products = ({ products }) => {
  return (
    <>
      <div className="mx-auto max-w-7xl py-4 px-4 sm:px-8 sm:py-32 lg:px-8 lg:pt-8">
        <div className="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
          {products?.map((product) => (
            <Link
              to={`/products/${product._id}`}
              key={product.id}
              className="group relative">
              <div className="h-56 w-full overflow-hidden rounded-md group-hover:opacity-75 lg:h-72 xl:h-80 ">
                <img
                  src={product.images[0]}
                  alt={product.images[0]}
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <h3 className="flex justify-center items-center mt-4 text-sm text-gray-700 dark:text-gray-400 ">
                <span className="absolute inset-0" />
                {product.name.substring(0, 20)}
              </h3>
              <p dir="rtl" className="flex justify-center items-center mt-1 text-sm font-medium text-gray-900 dark:text-white">
                 {product.price} د.ك.
              </p>
              <p className="flex justify-center items-center mt-1 text-sm text-gray-500 dark:text-gray-500">
                {`${product.category}`.charAt(0).toUpperCase() + `${product.category}`.slice(1)}
              </p>
              {/* Color picker */}
              <div>
                <div className="flex justify-center items-center space-x-3">
                  <RadioGroup>
                    <div className="mt-2 mb-4 flex items-center space-x-3">
                      {product?.colors?.slice(0,3).map((color) => (
                        <RadioGroup.Option
                          className="-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none">
                          <span
                            style={{ backgroundColor: color }}
                            aria-hidden="true"
                            className="h-6 w-6 border border-black border-opacity-10 rounded-full"
                          />
                        </RadioGroup.Option>
                      ))}
                      {product?.colors?.length > 3 ? (
                        <>
                        <p>+{product?.colors?.length - 3}</p>
                        </>
                      ) : ''}
                    </div>
                  </RadioGroup>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Products;

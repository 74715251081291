import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchProductsAction } from "../../redux/slices/products/productSlices";
import baseURL from "../../utils/baseURL";
import { RadioGroup } from "@headlessui/react";
import { Button } from "flowbite-react";
import { useInfiniteQuery } from "@tanstack/react-query";

const titles = [
  {
    title: ["منتجات الأكثر مبيعاً "],
  },
]

const HomeProductTrending = () => {

  const [page, setPage] = useState(1);

  //build up url
  let productUrl = `${baseURL}/products?page=1&limit=8`;

  if (page) {
    productUrl = `${baseURL}/products?page=${page}&limit=8`;
  }

  //dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchProductsAction({
        url: productUrl,
      })
    );
  }, [dispatch, page]);
  //get data from store
  const {
    products: { products },
    error,
    loading,
  } = useSelector((state) => state?.products);

  return (
    <>
      <section aria-labelledby="trending-heading">
        <div className="mx-auto max-w-7xl py-4 px-4 sm:px-8 sm:py-32 lg:px-8 lg:pt-8">
          <div dir="rtl" className="md:flex md:items-center md:justify-between">
            <h2
              id="favorites-heading"
              className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {titles[0].title[0]}
            </h2>

          </div>
          <div className="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
            {products?.map((product) => (
              <Link
                to={`/products/${product._id}`}
                key={product.id}
                className="group relative">
                <div className="h-56 w-full overflow-hidden rounded-md group-hover:opacity-75 lg:h-72 xl:h-80 ">
                  <img
                    src={product.images[0]}
                    alt={product.images[0]}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <h3 className="flex justify-center items-center mt-4 text-sm text-gray-700 dark:text-gray-400 ">
                  <span className="absolute inset-0" />
                  {product.name.substring(0, 20)}
                </h3>
                <p dir="rtl" className="flex justify-center items-center mt-1 text-sm font-medium text-gray-900 dark:text-white">
                   {product.price} .د.ك
                </p>
                <p className="flex justify-center items-center mt-1 text-sm text-gray-500 dark:text-gray-500">
                  {`${product.category}`.charAt(0).toUpperCase() + `${product.category}`.slice(1)}
                </p>
                {/* Color picker */}
                <div>
                  <div className="flex justify-center items-center space-x-3">
                    <RadioGroup>
                      <div className="mt-2 mb-4 flex items-center space-x-3">
                        {product?.colors?.map((color) => (
                          <RadioGroup.Option
                            className="-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none">
                            <span
                              style={{ backgroundColor: color }}
                              aria-hidden="true"
                              className="h-6 w-6 border border-black border-opacity-10 rounded-full"
                            />
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          {/* Pagnation */}
          <div class="flex items-center justify-center">
            {page != 1 ?
              <Button onClick={() => setPage(page - 1)} href="#" class="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 19-7-7 7-7" />
                </svg>

              </Button>
              : <button disabled onClick={() => setPage(page - 1)} href="#" class="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-gray-400 border border-gray-300 rounded-lg hover:bg-gray-900 hover:text-gray-700 dark:bg-gray-300 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-400 dark:hover:text-white">
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 19-7-7 7-7" />
                </svg>

              </button>
            }
            {products?.length < 8 ?
              <button disabled onClick={() => setPage(page + 1)} href="#" class="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-gray-400 border border-gray-300 rounded-lg hover:bg-gray-900 hover:text-gray-700 dark:bg-gray-300 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-400 dark:hover:text-white">
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7" />
                </svg>

              </button> :
              <Button onClick={() => setPage(page + 1)} href="#" class="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7" />
                </svg>

              </Button>
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeProductTrending;

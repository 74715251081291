
const locationItems = [
    {
        title:"الموقع",
        name: "شركة بيم - شارع خالد بن وليد ، برج الصوابر ٦ دور السادس مكتب ٥ ، شرق ، مدينة الكويت",
    },
];

export default function Location({ open, onClose, children }) {
    return (
        <>
            <div onClick={onClose} className={`fixed inset-0 flex justify-center items-center transition-colors z-1000 ${open ? "visible bg-black/20" : "invisible"}`}>
                <div class={`bg-white dark:bg-gray-400 rounded-xl shadow p-6 transition-all  ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}`} >
                    {children}
                    {/* Modal content */}
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 z-50">
                        {/* Modal header */}
                        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                            {locationItems[0].title}
                            </h3>
                            <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>
                        {/*  Modal body  */}
                        <div class="p-4 md:p-5 space-y-4">
                            <a href="https://maps.app.goo.gl/tsXSXqSBcLkP1Thp6" class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            {locationItems[0].name}
                            </a>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3476.7719698263927!2d47.98233431275637!3d29.37696527516321!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3fcf849441e71957%3A0x5917ce3248898c70!2sSuper%20Technology%20Center%20-%20Khalid%20Bin%20Waleed%20Street%20%2C%20Sawaber%206%20Building%20%2C%206th%20floor%20%2C%20Office%20No.%209%20%2C%20Sharq%20%2C%20kuwait%20City.!5e0!3m2!1sen!2skw!4v1716070770014!5m2!1sen!2skw"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


import { useEffect, useState, useRef } from "react";
import { RadioGroup } from "@headlessui/react";
import Swal from "sweetalert2";
import {
    CreditCardIcon,
    TruckIcon,
} from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/20/solid";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductAction } from "../../../redux/slices/products/productSlices";
import {
    addOrderToCartaction,
    getCartItemsFromLocalStorageAction,
} from "../../../redux/slices/cart/cartSlices";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';


const imgClasses = {
    imgs: ["h-full w-full object-cover object-center"],
    divs: ["aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block", "aspect-h-2 aspect-w-3 overflow-hidden rounded-lg", "aspect-h-2 aspect-w-3 overflow-hidden rounded-lg", "aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg"],
};

const titles = [
    {
        title: ["ألوان", "مقاسات", "تفاصيل المنتج", "قيم المنتج", "تقييمات", "تقييمات مؤخرة", "أضف للسلة", "اذهب للشراء"],
    },
];

const policy = [
    {
        name: "التوصيل",
        icon: TruckIcon,
        description: '',
    },
    {
        name: "سياسة الاسترجاع",
        icon: CreditCardIcon,
        description: "البضاعة المباعة لا ترد أو تستبدل",
    },
];

const productHeadlines = ['الطول', 'الكتف', 'الكم', 'الصدر',]

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function SizeProduct () {

    //get id from params
    const { id } = useParams();
    useEffect(() => {
        dispatch(fetchProductAction(id));
    }, [id]);
    //get data from store
    const {
        loading,
        error,
        product: { product },
    } = useSelector((state) => state?.products);

    //dispatch
    const dispatch = useDispatch();
    const [selectedSize, setSelectedSize] = useState("");
    const [selectedColor, setSelectedColor] = useState("");

    const [sizeFormData, setSizeFormData] = useState({
        length: '',
        shoulder: '',
        sleeve: '',
        chest: '',
    });

    //onchange
    const onChange = (e) => {
        setSizeFormData({ ...sizeFormData, [e.target.name]: e.target.value });
    };

    //Get cart items
    useEffect(() => {
        dispatch(getCartItemsFromLocalStorageAction());
    }, []);
    //get data from store
    const { cartItems } = useSelector((state) => state?.carts);
    const productExists = cartItems?.find(
        (item) => item?._id?.toString() === product?._id.toString()
    );

    if (product?.colors.length === 1 && selectedColor === "") {
        setSelectedColor(product?.colors[0]);
    }

    //Add to cart handler
    const addToCartHandler = () => {

        //check if product is in cart
        if (productExists) {
            return Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "This product is already in cart",
            });
        }
        //check if color/size selected
        if (selectedColor === "") {
            return Swal.fire({
                icon: "error",
                title: "هناك مشكلة",
                text: "اختر اللون",
            });
        }
        if (selectedSize === "") {
            return Swal.fire({
                icon: "error",
                title: "هناك مشكلة",
                text: "ادخل القياس",
            });
        }

        dispatch(
            addOrderToCartaction({
                _id: product?._id,
                name: product?.name,
                qty: 1,
                price: product?.price,
                description: product?.description,
                color: selectedColor,
                size: selectedSize,
                image: product?.images[0],
                totalPrice: product?.price,
                qtyLeft: product?.qtyLeft,
                deliveryFee: product?.deliveryFee
            })
        );
        console.log(sizeFormData);
        Swal.fire({
            icon: "success",
            title: "احسنت",
            text: "تم اضافة المنتج",
        });
        return dispatch(getCartItemsFromLocalStorageAction());
    };

    const [activeImg, setActiveImage] = useState(product?.images[0]);

    return (
        <div className="bg-white dark:bg-gray-800 dark:text-white pt-8">
            <main className="mx-auto max-w-2xl px-4 pb-16 sm:px-6 sm:pb-24 lg:max-w-7xl lg:px-8">
                {/* Image Gallery */}
                {loading ? (
                    <LoadingComponent />
                ) : (
                    <div>
                        <div>
                            <Swiper
                                effect={'coverflow'}
                                grabCursor={true}
                                centeredSlides={true}
                                loop={true}
                                slidesPerView={'auto'}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 2.5,
                                }}
                                pagination={{ el: '.swiper-pagination', clickable: true }}
                                navigation={{
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                    clickable: true,
                                }}
                                modules={[EffectCoverflow, Pagination, Navigation]}
                                className="relative max-w-[40rem] h-[30rem] w-full m-auto"
                            >
                                {product?.images?.map((image) => (
                                    <SwiperSlide>
                                        <img className="h-full w-full object-scale-down  object-center rounded-md" src={image} alt="slide_image" />
                                    </SwiperSlide>
                                ))}
                                <div className="slider-controler">
                                    <div className="swiper-button-prev slider-arrow">
                                        <ion-icon name="arrow-back-outline"></ion-icon>
                                    </div>
                                    <div className="swiper-button-next slider-arrow">
                                        <ion-icon name="arrow-forward-outline"></ion-icon>
                                    </div>
                                    <div className="swiper-pagination"></div>
                                </div>
                            </Swiper>
                        </div>
                    </div>
                )}
                < div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
                    {/* Product details */}
                    <div dir="rtl" className="lg:col-span-2  lg:border-gray-200 lg:pr-8">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl dark:text-white">{product?.name}</h1>
                        <div className="mt-10">
                            <h2 className="text-sm font-medium text-gray-900 dark:text-white">{titles[0].title[2]}</h2>
                            <div className="prose prose-sm mt-4 text-gray-500 dark:border-gray-600">
                                {product?.description}
                            </div>
                        </div>
                    </div>

                    {/* Options */}
                    <div className="mt-4 lg:row-span-3 lg:mt-0">
                        <div dir="rtl" className="lg:col-span-2 lg:border-l lg:border-gray-200 lg:pl-8">
                            <h2 className="sr-only">Product information</h2>
                            <p className="text-3xl tracking-tight text-gray-900 dark:text-white">{product?.price} د.ك.</p>

                            {/* Reviews */}
                            <div className="mt-6">
                                <h3 className="sr-only">Reviews</h3>
                                <div className="flex items-center">
                                    <div className="flex items-center">
                                        {product?.reviews?.length > 0 ? product?.averageRating : 0}
                                        {[0, 1, 2, 3, 4].map((rating) => (
                                            <StarIcon
                                                key={rating}
                                                className={classNames(
                                                    product?.averageRating > rating ? 'text-gray-900' : 'text-gray-200',
                                                    'h-5 w-5 flex-shrink-0'
                                                )}
                                                aria-hidden="true"
                                            />
                                        ))}
                                    </div>
                                    <p className="sr-only">{product?.averageRating} out of 5 stars</p>
                                    <a className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500 dark:border-gray-600">
                                        {product?.reviews?.length} {titles[0].title[4]}
                                    </a>
                                </div>
                                {/* leave a review */}
                                <div className="mt-4">
                                    <Link to={`/add-review/${product?._id}`}>
                                        <h3 className="text-sm font-medium text-blue-600 dark:border-gray-600">
                                            {titles[0].title[3]}
                                        </h3>
                                    </Link>
                                </div>
                            </div>

                            <>
                                {/* Color picker */}
                                {product?.colors.length === 1 ? '' : (
                                    <div>
                                        <h2 className="text-sm font-medium text-gray-900 dark:text-white">{titles[0].title[0]}</h2>
                                        <div className="flex items-center space-x-3">
                                            <RadioGroup value={selectedColor} onChange={setSelectedColor}>
                                                <div dir="ltr" className="mt-4 flex items-center space-x-3">
                                                    {product?.colors?.map((color) => (
                                                        <RadioGroup.Option
                                                            key={color}
                                                            value={color}
                                                            className={({ active, checked }) =>
                                                                classNames(
                                                                    active && checked ? "ring ring-offset-1" : "",
                                                                    !active && checked ? "ring-2" : "",
                                                                    "-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none"
                                                                )
                                                            }>
                                                            <RadioGroup.Label as="span" className="sr-only">
                                                                {color}
                                                            </RadioGroup.Label>
                                                            <span
                                                                style={{ backgroundColor: color }}
                                                                aria-hidden="true"
                                                                className={classNames(
                                                                    "h-8 w-8 border border-black border-opacity-10 rounded-full"
                                                                )}
                                                            />
                                                        </RadioGroup.Option>
                                                    ))}
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </div>
                                )}

                                {/* Size picker */}
                                <div className="mt-8">
                                    <div className="flex items-center justify-between">
                                        <h2 className="text-sm font-medium text-gray-900 dark:text-white">{titles[0].title[1]}</h2>
                                    </div>
                                    <RadioGroup
                                        value={selectedSize}
                                        onChange={setSelectedSize}
                                        className="mt-2">
                                        {/* Choose size */}
                                        <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
                                            {product?.sizes?.map((size) => (
                                                <RadioGroup.Option
                                                    key={size}
                                                    value={size}
                                                    className={({ active, checked }) => {
                                                        return classNames(
                                                            checked
                                                                ? "bg-indigo-600 border-transparent  text-white hover:bg-indigo-700"
                                                                : "bg-white dark:bg-gray-600 dark:text-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                                            "border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 cursor-pointer"
                                                        );
                                                    }}>
                                                    <RadioGroup.Label as="span">{size}</RadioGroup.Label>
                                                </RadioGroup.Option>
                                            ))}
                                        </div>
                                    </RadioGroup>
                                </div>

                                {/* add to cart */}
                                {product?.qtyLeft <= 0 ? (
                                    <button
                                        style={{ cursor: "not-allowed" }}
                                        disabled
                                        className="mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-gray-600 py-3 px-8 text-base font-medium text-whitefocus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                        Add to cart
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => addToCartHandler()}
                                        className="mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                        {titles[0].title[6]}
                                    </button>
                                )}
                                {/* proceed to check */}

                                {cartItems.length > 0 && (
                                    <Link
                                        to="/shopping-cart"
                                        className="mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-green-800 py-3 px-8 text-base font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                        {titles[0].title[7]}
                                    </Link>
                                )}
                            </>

                            {/* Policies */}
                            <section aria-labelledby="policies-heading" className="mt-10">
                                <h2 id="policies-heading" className="sr-only">
                                    Our Policies
                                </h2>

                                <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">

                                    <div
                                        key={policy[0].name}
                                        className="rounded-lg border border-gray-200 bg-gray-50 dark:bg-gray-600 p-6 text-center">
                                        <dt>
                                            <TruckIcon
                                                className="mx-auto h-6 w-6 flex-shrink-0 text-gray-400"
                                                aria-hidden="true"
                                            />
                                            <span className="mt-4 text-sm font-medium text-gray-900 dark:text-white">
                                                {policy[0].name}
                                            </span>
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                                            التوصيل من {product?.deliveryPeriod[0]} إلى {product?.deliveryPeriod[1]} أيام
                                        </dd>
                                    </div>
                                    <div
                                        key={policy[1].name}
                                        className="rounded-lg border border-gray-200 bg-gray-50 dark:bg-gray-600 p-6 text-center">
                                        <dt>
                                            <CreditCardIcon
                                                className="mx-auto h-6 w-6 flex-shrink-0 text-gray-400"
                                                aria-hidden="true"
                                            />
                                            <span className="mt-4 text-sm font-medium text-gray-900 dark:text-white">
                                                {policy[1].name}
                                            </span>
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                                            {policy[1].description}
                                        </dd>
                                    </div>

                                </dl>
                            </section>
                        </div>
                    </div>
                </div>
                {/* Reviews */}
                <section dir="rtl" aria-labelledby="reviews-heading" className="mt-16 sm:mt-24">
                    <h2
                        id="reviews-heading"
                        className="text-lg font-medium text-gray-900 dark:text-white">
                        {titles[0].title[5]}
                    </h2>

                    <div className="mt-6 space-y-10 divide-y divide-gray-200 border-t border-b border-gray-200 pb-10">
                        {product?.reviews.map((review) => (
                            <div
                                key={review._id}
                                className="pt-10 lg:grid lg:grid-cols-12 lg:gap-x-8">
                                <div className="lg:col-span-8 lg:col-start-5 xl:col-span-9 xl:col-start-4 xl:grid xl:grid-cols-3 xl:items-start xl:gap-x-8">
                                    <div className="flex items-center xl:col-span-1">
                                        <div className="flex items-center">
                                            {[0, 1, 2, 3, 4].map((rating) => (
                                                <StarIcon
                                                    key={rating}
                                                    className={classNames(
                                                        review.rating > rating
                                                            ? "text-yellow-400"
                                                            : "text-gray-200",
                                                        "h-5 w-5 flex-shrink-0"
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            ))}
                                        </div>
                                        <p className="ml-3 text-sm text-gray-700 dark:text-white">
                                            {review.rating}
                                            <span className="sr-only"> out of 5 stars</span>
                                        </p>
                                    </div>

                                    <div className="mt-4 lg:mt-6 xl:col-span-2 xl:mt-0">
                                        <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                                            {review?.message}
                                        </h3>
                                    </div>
                                </div>

                                <div className="mt-6 flex items-center text-sm lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:mt-0 lg:flex-col lg:items-start xl:col-span-3">
                                    <p className="font-medium text-gray-900 dark:text-white">
                                        {review.user?.fullname}
                                    </p>
                                    <time
                                        dateTime={review.datetime}
                                        className="ml-4 border-l border-gray-200 pl-4 text-gray-500 dark:text-gray-400 lg:ml-0 lg:mt-2 lg:border-0 lg:pl-0">
                                        {new Date(review.createdAt).toLocaleDateString()}
                                    </time>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </main >
        </div >
    );
}


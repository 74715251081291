import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Products from "../Users/Products/Products";
import { useSearchParams } from "react-router-dom";
import baseURL from "../../utils/baseURL";
import { fetchProductsAction } from "../../redux/slices/products/productSlices";
import ErrorMsg from "../ErrorMsg/ErrorMsg";
import NoDataFound from "../NoDataFound/NoDataFound";
import LoadingComponent from "../LoadingComp/LoadingComponent";
import { Button } from "flowbite-react";

const titles = [
    {
        title: ["المنتجات", "لون", "سعر", "ماركات", "مقاس"],
    },
];


export default function BrandPage() {
    //dispatch
    const dispatch = useDispatch();

    const [params, setParams] = useSearchParams();
    const category = params.get("category");

    const [page, setPage] = useState(1);

    //build up url
    let productUrl = `${baseURL}/products`;
    if (category) {
        productUrl = `${baseURL}/products?category=${category}&page=3&limit=8`;
    }

    if (page) {
        productUrl = `${baseURL}/products?category=${category}&page=${page}&limit=8`;
    }

    //fetch all products
    useEffect(() => {
        dispatch(
            fetchProductsAction({
                url: productUrl,
            })
        );
    }, [dispatch, category, page]);
    //get store data
    const {
        products: { products },
        loading,
        error,
    } = useSelector((state) => state?.products);

    return (
        <div className="bg-white dark:bg-gray-800 dark:text-white flex items-center justify-center">

            <div>
                <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div dir="rtl" className="flex items-baseline justify-between border-b border-gray-200 pt-24 pb-6">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-900 dark:text-white">
                            {titles[0].title[0]}
                        </h1>
                    </div>

                    <section aria-labelledby="products-heading" className="pt-6 pb-24">
                        <h2 id="products-heading" className="sr-only">
                            Products
                        </h2>

                        <div>
                            {/* Desktop  Filters */}


                            {/* Product grid */}
                            {loading ? (
                                <LoadingComponent />
                            ) : error ? (
                                <ErrorMsg message={error?.message} />
                            ) : products?.length <= 0 ? (
                                <NoDataFound />
                            ) : (
                                <Products products={products} />
                            )}
                        </div>


                        {/* Pagnation */}
                        <div class="flex items-center justify-center">
                            {page != 1 ?
                                <Button onClick={() => setPage(page - 1)} href="#" class="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 19-7-7 7-7" />
                                    </svg>

                                </Button>
                                : <button disabled onClick={() => setPage(page - 1)} href="#" class="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-gray-400 border border-gray-300 rounded-lg hover:bg-gray-900 hover:text-gray-700 dark:bg-gray-300 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-400 dark:hover:text-white">
                                    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 19-7-7 7-7" />
                                    </svg>

                                </button>
                            }
                            {products?.length < 8 ?
                                <button disabled onClick={() => setPage(page + 1)} href="#" class="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-gray-400 border border-gray-300 rounded-lg hover:bg-gray-900 hover:text-gray-700 dark:bg-gray-300 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-400 dark:hover:text-white">
                                    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7" />
                                    </svg>

                                </button> :
                                <Button onClick={() => setPage(page + 1)} href="#" class="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7" />
                                    </svg>

                                </Button>
                            }
                        </div>
                    </section>
                </main>
            </div>
        </div>
    );
}

import { useEffect, useState, useRef } from "react";
import {
  CreditCardIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductAction } from "../../../redux/slices/products/productSlices";
import MeasurmentProduct from "./MeasurmentProduct";
import NoColorNoSizeProduct from "./NoColorNoSizeProduct";
import SizeProduct from "./SizeProduct";
import CarvingProduct from "./CarvingProduct";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../LoadingComp/LoadingComponent";


const imgClasses = {
  imgs: ["h-full w-full object-cover object-center"],
  divs: ["aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block", "aspect-h-2 aspect-w-3 overflow-hidden rounded-lg", "aspect-h-2 aspect-w-3 overflow-hidden rounded-lg", "aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg"],
};

const titles = [
  {
    title: ["ألوان", "مقاسات", "تفاصيل المنتج", "قيم المنتج", "تقييمات", "تقييمات مؤخرة", "أضف للسلة", "اذهب للشراء"],
  },
];

const policy = [
  {
    name: "التوصيل",
    icon: TruckIcon,
    description: '',
  },
  {
    name: "سياسة الاسترجاع",
    icon: CreditCardIcon,
    description: "البضاعة المباعة لا ترد أو تستبدل",
  },
];

const productHeadlines = ['الطول', 'الكتف', 'الكم', 'الصدر',]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Product() {

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  //dispatch
  const dispatch = useDispatch();

  //get id from params
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchProductAction(id));
  }, [id]);
  //get data from store
  const {
    loading,
    error,
    product: { product },
  } = useSelector((state) => state?.products);

  return (
    <>
     {
        product?.sizes.find((element) => element === 'Measurements') ?  (
        <>
           <MeasurmentProduct />
        </>
       ) : product?.sizes.find((element) => element === 'N/A') ? (
        <>
            <NoColorNoSizeProduct />
        </>
       ) : product?.sizes.find((element) => element === 'Carving') ? (
        <>
            <CarvingProduct />
        </>
       ) : (
        <>
           <SizeProduct />
        </>
       )}
    </>
  );
}

import { Link } from "react-router-dom";
import HomeCategories from "./HomeCategories";
import HomeProductTrending from "./HomeProductTrending";
import Carousel from "./carouselHomePage";

const titles = [
  {
    title: ["تسوق بالماركات"],
  },
]

export default function Example() {
  return (
    <div className="bg-white dark:bg-gray-800  dark:text-white">
      <main>
        <Carousel />
        {/* Brand section */}
        <section
          aria-labelledby="category-heading"
          className="pt-8 sm:pt-8 xl:mx-auto xl:max-w-7xl xl:px-8"
        >
          <div dir="rtl" className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0">
            <h2
              id="category-heading"
              className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
            >
              <Link
                to="/all-categories"
                className="inline-block"
              >
                {titles[0].title[0]} <span aria-hidden="true">&larr;</span>
              </Link>
            </h2>
          </div>
          {/* home categories */}
          <HomeCategories />
        </section>
        {/* Home trending trending */}
        <HomeProductTrending />

      </main>
    </div>
  );
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileAction } from "../../../redux/slices/users/usersSlice";
import CustomerDetails from "./CustomerDetails";
import ShippingAddressDetails from "./ShippingAddressDetails";

const customerProfileHeadlines = [
  'طلب','تفاصيل الطلب','حالة الدفع','تاريخ الطلب','مرحباً','حالة الطلب','حالة التوصيل','في الانتظار',''
]

export default function CustomerProfile() {
  //dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);
  //get data from store
  const { error, loading, profile } = useSelector((state) => state?.users);
  //get orders
  const orders = profile?.user?.orders;

  return (
    <>
      <div className="bg-white dark:bg-gray-900 flex flex-wrap pt-2 -mx-3 -mb-3 md:mb-0">
        <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0" />
        <div className="w-full md:w-1/2 px-3 mb-3 md:mb-0">
          {/* <CustomerDetails
            email={profile?.user?.email}
            dateJoined={new Date(profile?.user?.createdAt).toDateString()}
            fullName={profile?.user?.fullname}
          /> */}
        </div>
        <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0" />
      </div>

      {loading ? (
        <h2>Loading...</h2>
      ) : error ? (
        <h2>{error?.message}</h2>
      ) : orders?.length <= 0 ? (
        <h2 className="text-center mt-10">No Order Found</h2>
      ) : (
        orders?.map((order) => {
          return (
            <>
              <section dir="rtl" class="bg-white dark:bg-gray-900 py-8 antialiased  md:py-16">
                <div class="mx-auto max-w-screen-xl px-4 2xl:px-0">
                  <h2 class="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">{customerProfileHeadlines[0]} #{order?.orderNumber}</h2>

                  <div class="mt-6 sm:mt-8 lg:flex lg:gap-8">

                      <div class="w-full divide-y divide-gray-200 overflow-hidden rounded-lg border border-gray-200 dark:divide-gray-700 dark:border-gray-700 lg:max-w-xl xl:max-w-2xl">
                      {order?.orderItems?.map((product) => (
                        <div dir='rtl' class="space-y-4 p-6">
                          <div class="flex items-center gap-6">

                            <a href="#" class="h-14 w-14 shrink-0">
                              <img class="h-full w-full dark:hidden" src={product.image} alt={product.image} />
                              <img class="hidden h-full w-full dark:block" src={product.image} alt={product.image} />
                            </a>

                            <a href="#" class="min-w-0 flex-1 font-medium text-gray-900 hover:underline dark:text-white"> {product.name} </a>
                          </div>

                          <div class="flex items-center justify-between gap-4">
                            <p class="text-sm font-normal text-gray-500 dark:text-gray-400"><span class="font-medium text-gray-900 dark:text-white">{customerProfileHeadlines[1]}:</span> {product.description.substring(0, 100)}</p>

                            <div class="flex items-center justify-end gap-4">
                              <p class="text-base font-normal text-gray-900 dark:text-white">X{product.qty}</p>

                              <p class="text-xl font-bold leading-tight text-gray-900 dark:text-white">{product.price} د.ك.</p>
                            </div>
                          </div>
                        </div>
                        ))}
                      </div>

                    <div class="mt-6 grow sm:mt-8 lg:mt-0">
                      <div class="space-y-6 rounded-lg border border-gray-200 bg-white p-6 shadow-sm dark:border-gray-700 dark:bg-gray-800">
                        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">{customerProfileHeadlines[3]}</h3>

                        <ol class="relative ms-3 border-s border-gray-200 dark:border-gray-700">

                          <li class="mb-10 ms-6">
                            <h4 class="mb-0.5 text-base font-semibold text-gray-900 dark:text-white">{customerProfileHeadlines[6]} - {order.status === "delivered" ? "تم تسليم طلبك" : "" }
                            <span class=" flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white dark:bg-gray-700 dark:ring-gray-800">
                              <svg class="h-4 w-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5" />
                              </svg>
                            </span>
                            </h4>
                          </li>

                          <li class="mb-10 ms-6 text-primary-700 dark:text-primary-500">
                            <h4 class="mb-0.5 font-semibold dark:text-white">{customerProfileHeadlines[6]} - {order.status === "shipped" ? "سيصلك اليوم" : customerProfileHeadlines[7]  }
                            <span class="absolute -start-3 flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white dark:bg-gray-700 dark:ring-gray-800">
                              <svg class="h-4 w-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
                              </svg>
                            </span></h4>
                          </li>

                          <li class="mb-10 ms-6 text-primary-700 dark:text-primary-500">
                            <h4 class="mb-0.5 text-base font-semibold dark:text-white">{customerProfileHeadlines[5]} - {order.status === "processing" ? "قيد التنفيذ" : customerProfileHeadlines[7] }
                            <span class="absolute -start-3 flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white dark:bg-gray-900 dark:ring-gray-800">
                              <svg class="h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 11.917 9.724 16.5 19 7.5" />
                              </svg>
                            </span></h4>
                          </li>

                          <li class="mb-10 ms-6 text-gray-700 dark:text-gray-500">
                            <h4 class="mb-0.5 font-semibold dark:text-white">{customerProfileHeadlines[2]} - {order.paymentStatus === "CAPTURED" ? "تم الدفع" : "مشكلة في الدفع" }
                            <span class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white dark:bg-gray-900 dark:ring-gray-800">
                              <svg class="h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 11.917 9.724 16.5 19 7.5" />
                              </svg>
                            </span>
                            </h4>
                          </li>

                          <li class="ms-6 text-gray-700 dark:text-gray-500">
                            <div>
                              <h4 class="mb-0.5 font-semibold dark:text-white">{customerProfileHeadlines[0]} #{order?.orderNumber}
                              <span class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white dark:bg-gray-900 dark:ring-gray-800">
                              <svg class="h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 11.917 9.724 16.5 19 7.5" />
                              </svg>
                            </span>
                              </h4>

                            </div>

                          </li>
                        </ol>
{/* 
                        <div class="gap-4 sm:flex sm:items-center">
                          <button type="button" class="w-full rounded-lg  border border-gray-200 bg-white px-5  py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700">Cancel the order</button>

                          <a href="#" class="mt-4 flex w-full items-center justify-center rounded-lg bg-gray-700  px-5 py-2.5 text-sm font-medium text-white hover:bg-gray-800 focus:outline-none focus:ring-4 focus:ring-gray-300  dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 sm:mt-0">Order details</a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          );
        })
      )}
    </>
  );
}
